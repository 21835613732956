<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="d-flex">
          <img
            src="@/assets/icons/age-verification.svg"
            height="22"
            width="22"
            alt="age-icon"
          />
          <div class="main-heading">
            Age Estimation
            <v-icon class="info-icon" v-if="!alert" @click="alert = true"
              >mdi-information-outline</v-icon
            >
          </div>
        </v-col>
      </v-row>
      <v-row class="secnod-row">
        <v-col cols="12">
          <v-alert v-model="alert" close-text="Close Alert" color="age-alert">
            <template v-slot:append>
              <img
                @click="alert = false"
                src="@/assets/icons/close-icon.svg"
                height="22"
                width="22"
                class="cursor-pointer"
                alt="close-icon"
              />
            </template>
            Experience seamless and age confirmation with our Age Estimation
            Service, powered by advanced facial recognition technology. Simply
            upload an existing image or capture a new one in real-time. Our
            system swiftly analyzes facial features to estimate the age of the
            individual.
          </v-alert>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            class="age-verification-card"
            width="640"
            style="height: 100%"
          >
            <v-card-title class="card-title"> Selfie/Image </v-card-title>
            <v-card-text
              class="card-body"
              :class="
                $vuetify.breakpoint.smAndDown && imageSrc
                  ? 'card-body-mobile'
                  : ''
              "
            >
              <div v-if="!imageSrc" class="selfie-upload">
                <div class="option">
                  <div class="option-child" @click="takeSelfie()">
                    <img
                      src="@/assets/icons/camera-icon.svg"
                      height="20"
                      width="20"
                      alt="camera-icon"
                    />
                    <span class="option-text">Take Selfie</span>
                  </div>
                  <v-divider class="vertical-divider" vertical></v-divider>
                  <div class="option-child" @click="browseClick()">
                    <img
                      src="@/assets/icons/file-upload.svg"
                      height="20"
                      width="20"
                      alt="file-upload-icon"
                    />
                    <div class="option-text">Upload Selfie</div>
                  </div>
                </div>
                <div cols="6" class="file-limit-text">Maximum size 3 MB</div>
              </div>
              <v-row v-if="imageSrc" style="width: 100%">
                <v-col cols="12" class="pa-0">
                  <img
                    class="preview-img"
                    :class="
                      $vuetify.breakpoint.xsOnly ? 'preview-img-mobile' : ''
                    "
                    id="image-preview"
                    :src="imageSrc"
                    alt=""
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="card-footer">
              <div v-if="showResult">
                <span v-if="isAgeExit > 0">
                  The Subject is
                  <span class="substr-text"> {{ searchResult }}</span>
                  old.</span
                >
                <span v-else>The subject age could not be verified</span>
              </div>
              <div v-if="isProcessing">
                <v-progress-circular
                  :width="3"
                  color="#173dd0"
                  indeterminate
                ></v-progress-circular>
                <span class="pl-2"> Processing Data...</span>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="showResult">
        <v-col cols="12" class="d-flex justify-center result-btns">
          <router-link
            class="text-decoration-none"
            :to="`/transactions/${referenceId}`"
            target="_blank"
          >
            <v-btn depressed class="view-result">
              <span class="px-1">View Results</span>
              <img
                src="@/assets/icons/arrow-next.svg"
                height="15"
                width="15"
                alt="next-arrow"
              />
            </v-btn>
          </router-link>
          <v-btn @click="retry" depressed class="try-more">
            <span class="px-1">Try More</span>
            <img
              src="@/assets/icons/retry-icon.svg"
              height="12"
              width="12"
              alt="next-arrow"
            />
          </v-btn>
        </v-col>
      </v-row>
      <Camera
        v-if="startCamera"
        @attached-image="getImage"
        @close-camera-dialog="closeCamera"
        :showOval="true"
      />
      <input
        ref="file-uploader"
        id="upload-file"
        class="d-none"
        type="file"
        accept="image/jpeg,image/JPEG,image/jpg,image/JPG,image/PNG,image/png"
        @change="onFileChanged($event)"
      />
    </v-container>
  </div>
</template>

<script>
import Camera from "../common/Camera.vue";
import ApiService from "../../services/Api";
import {
  showErrorMessage,
  showSimpleErrorMessage,
} from "../../utils/showError";
import { API_URL } from "@/utils/env.js";
export default {
  name: "AgeVerification",
  components: { Camera },
  data() {
    return {
      startCamera: false,
      isProcessing: false,
      showResult: false,
      ageResultInterval: false,
      setTimeoutInterval: null,
      searchResult: [],
      baseUrl: API_URL,
      selfieImage: "",
      clientSecret: null,
      alert: true,
      imageSrc: null,
      fileSizeLimit: 3 * 1024 * 1024,
      referenceId: null,
      isAgeExit: 0,
    };
  },

  methods: {
    /**
     * Initiates the process of taking a selfie by starting the camera.
     */
    takeSelfie() {
      this.startCamera = true;
    },

    /**
     * Receives the captured image data, sets the image source, and prepares the image blob for further processing.
     * @param {Object} data - The captured image data containing base64Image and file.
     */
    getImage(data) {
      this.imageSrc = data.base64Image;
      this.selfieCameraBlob = data.file;
      this.sendForComparing(data.file);
    },

    /**
     * Handles the image upload event by displaying the uploaded image, reading the image file, and preparing the image blob for further processing.
     * @param {Event} event - The image upload event.
     */
    handleImageUpload(event) {
      const file = event.target.files[0];
      this.selfieImage = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        this.getImage(blob);
      };
    },

    /**
     * Sends the captured selfie image for age estimation comparison.
     * Initiates a transaction to compare the selfie image for age estimation.
     */
    sendForComparing() {
      this.isProcessing = true;

      ApiService.GetRequest("/merchant/client")
        .then((res) => {
          this.clientSecret = res.data.result.data.client_secret;
          const formData = new FormData();
          formData.append("type", "age_estimation");
          formData.append("file", this.selfieCameraBlob);
          ApiService.PostRequest(
            "age-estimation",
            formData,
            "iframe",
            this.clientSecret
          )
            .then((res) => {
              this.ageResultInterval = setInterval(() => {
                this.fetchFacedResults(res.data.result.data.reference_id);
              }, 2000);
              this.stopInterval();
            })
            .catch((error) => {
              showErrorMessage(error);
              setTimeout(() => {
                location.reload();
              }, 1000);
              this.isProcessing = false;
              if (error.response.status === 401) {
                this.$store.dispatch("logout");
                this.$store.dispatch("SET_LOGOUT");
                this.$router.push("/");
              }
            });
        })
        .catch((error) => {
          this.isProcessing = true;
          showErrorMessage(error);
        });
    },

    /**
     * Fetches the age estimation results for a given reference ID.
     * @param {string} referenceId - The reference ID of the age estimation transaction.
     */
    fetchFacedResults(referenceId) {
      const formData = new FormData();
      formData.append("reference_id", referenceId);
      ApiService.PostRequest("result", formData, "iframe", this.clientSecret)
        .then((res) => {
          if (
            res.data.result?.data?.is_age_detected !== null &&
            res.data.result?.data?.age_txt != null
          ) {
            clearInterval(this.ageResultInterval);
            clearTimeout(this.setTimeoutInterval);
            this.isProcessing = false;
            this.showResult = true;
            this.searchResult = res.data.result.data.age_txt;
            this.isAgeExit = res.data.result.data.is_age_detected;
            this.referenceId = res.data.result.data.reference_id;
          }
        })
        .catch((error) => {
          clearInterval(this.ageResultInterval);
          clearTimeout(this.setTimeoutInterval);
          this.isProcessing = false;
          setTimeout(this.retry.bind(this), 2500);
          showErrorMessage(error);
        });
    },
    /**
     * @Description
     * 1. Set a timeout to stop the interval after 1 minutes (60000 milliseconds)
     * @param none
     * @return none
     **/
    stopInterval() {
      this.setTimeoutInterval = setTimeout(() => {
        clearInterval(this.ageResultInterval);
        this.isProcessing = false;
        showSimpleErrorMessage("Unable to Extract the Data.");
        setTimeout(this.retry.bind(this), 2500);
      }, 60000);
    },

    /**
     * Reloads the current window to retry the process.
     */
    retry() {
      window.location.reload();
    },

    /**
     * Closes the camera interface.
     */
    closeCamera() {
      this.startCamera = false;
    },
    /**
     * @Description
     * 1. this method is used to intilize the browse click
     * @param none
     * @return none
     **/
    browseClick() {
      this.$refs?.["file-uploader"]?.click();
    },
    /**
     * @Description
     * This action is used to assign a file to imageSrc / videoSrc variable
     *  **/
    onFileChanged(e) {
      if (
        e?.target?.files[0] &&
        e?.target?.files[0]?.size < this.fileSizeLimit
      ) {
        this.selfieCameraBlob = e?.target?.files[0];
        var oFReader = new FileReader();
        oFReader.readAsDataURL(document.getElementById("upload-file").files[0]);
        oFReader.onload = (oFREvent) => {
          this.imageSrc = oFREvent.target.result;
        };
        this.sendForComparing(this.selfieCameraBlob);
      } else {
        showSimpleErrorMessage("image size cannot be greater than 3 mb.");
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.ageResultInterval);
    clearTimeout(this.setTimeoutInterval);
  },
};
</script>

<style scoped>
.container {
  margin-top: 2rem;
}
.main-heading {
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-left: 10px;
}
.age-main-container {
  padding: 40px;
}
.age-alert {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fafafa;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.age-alert:deep(.v-alert__wrapper) {
  align-items: start;
}
.age-alert:deep(.v-alert__content) {
  text-align: justify;
  padding-right: 20px;
}
.age-verification-card {
  height: 300px;
  padding: 0 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.age-verification-card:deep(.v-card__text) {
  padding: 10px;
}
.card-title {
  display: flex;
  justify-content: center;
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.card-body {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px dashed #e3e3e3;
  background: #f7f7f7;
  overflow: hidden;
}
.card-body-mobile {
  height: auto;
}
.card-footer {
  display: flex;
  justify-content: center;
  color: #909295;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.file-limit-text {
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.vertical-divider {
  width: 5px;
  height: 20px;
  min-height: 20px;
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  align-self: end;
}
.option {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 5px;
}
.option-child {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 5px;
  cursor: pointer;
}
.option-text {
  color: #213cc8;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.substr-text {
  color: #213cc8;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 0 2px;
}
.preview-img {
  width: 100%;
  max-height: 210px;
  object-fit: contain;
  aspect-ratio: 16/9;
  border-radius: 6px;
}
.preview-img-mobile {
  width: 100%;
  max-height: 210px;
  object-fit: contain;
  aspect-ratio: 16/9;
  border-radius: 6px;
}
.result-btns {
  column-gap: 16px;
}
.result-btns .view-result {
  display: flex;
  column-gap: 4px;
  color: #292b30;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.result-btns .try-more {
  display: flex;
  column-gap: 4px;
  width: 110px;
  height: 32px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #213cc8 !important;
  color: #ffffff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.secnod-row {
  row-gap: 70px;
}
.main-heading:deep(.info-icon) {
  font-size: 16px;
}
.selfie-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}
</style>
